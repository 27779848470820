import React from 'react';
import { bubble as Menu } from 'react-burger-menu';

const Sidebar = (props) => {
  return (
    // Pass on our props
    <Menu {...props}>
      <a className='menu-item' href='#Home'>
        Home
      </a>
      <a className='menu-item' href='#iCancha'>
        iCancha
      </a>
      <a className='menu-item' href='#Centro-Deportivo'>
        Soy un Centro deportivo
      </a>
      <a className='menu-item' href='https://sari.icancha.com/' target='_blank' rel='noreferrer'>
        ingresas a SARI
      </a>
    </Menu>
  );
};

export default Sidebar;